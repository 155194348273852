import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, ViewEncapsulation } from '@angular/core';

import { EMPLOYEES_INFO, LOGIN_ERRORS_MAP } from './login-page.constants';
import { takeUntil } from 'rxjs/operators';
import { IEmployeeInfo } from './_types';
import { MppAutoUnsubscribeComponent, MppUserSessionService } from '@arturin/core';
import { ROUTE_PATHS } from '@am/root.constants';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'am-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'access-container' },
})
export class AmLoginPageComponent extends MppAutoUnsubscribeComponent {
  public isPasswordExposed = false;

  public readonly FORGOT_PASSWORD_PATH: string = ROUTE_PATHS.FORGOT_PASSWORD;

  public readonly form: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    rememberMe: true,
  });

  public readonly employeeInfo: IEmployeeInfo = this.getRandomEmployeeInfo();

  public constructor(
    private readonly userSessionService: MppUserSessionService,
    private readonly formBuilder: FormBuilder,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public login(): void {
    try {
      this.validateFormErrors();
      this.createUserSession();
    } catch (error) {
      const message: string = this.translateService.instant(LOGIN_ERRORS_MAP[error.message]);

      this.toastrService.error(message);
    }
  }

  public resetLoginAttemptErrors(): void {
    const emailErrors = this.form.controls.email.errors;
    const passwordErrors = this.form.controls.password.errors;

    if(emailErrors && emailErrors.invalidAttempt) {
      delete emailErrors.invalidAttempt;
      this.form.controls.email.setErrors(Object.keys(emailErrors).length ? emailErrors : null);
      this.form.controls.email.updateValueAndValidity();
    }

    if(passwordErrors && passwordErrors.invalidAttempt) {
      delete passwordErrors.invalidAttempt;
      this.form.controls.password.setErrors(Object.keys(passwordErrors).length ? passwordErrors : null);
      this.form.controls.password.updateValueAndValidity();
    }
  }

  private getRandomEmployeeInfo(): IEmployeeInfo {
    const index = Math.round(Math.random() * (EMPLOYEES_INFO.length - 1));

    return EMPLOYEES_INFO[index];
  }

  private validateFormErrors(): void {
    const emailErrors = this.form.controls.email.errors;

    if (emailErrors) {
      this.form.setErrors(emailErrors);
    }

    if (!this.form.errors) {
      return;
    }

    const errorKeys = Object.keys(this.form.errors);

    throw new Error(errorKeys[0]);
  }

  private createUserSession(): void {
    this.userSessionService
      .create$(this.form.value, this.form.value.rememberMe)
      .pipe(takeUntil(this.destroy$$))
      .subscribe(
        (redirectUrl) => {
          window.location.href = redirectUrl;
        },
        () => {
          this.form.controls.email.setErrors({ invalidAttempt: true });
          this.form.controls.password.setErrors({ invalidAttempt: true });
        }
      );
  }
}
